<template>
     <div class=" service body">
          <div class="container">
               <div class="columns">
                    <div class="column is-half">
                         <div class="vertical-align">
                              <div class="section-header-2">
                                   Integrated Building Management System
                              </div>
                              <p>
                                   Integrated Building Management System (IBMS) is a control system computerised with digital data, an intelligent
                                   network of electronic devices, designed to monitor and control the mechanical, electronics and lighting systems in
                                   a building. CTL designs IBMS solutions for corporates, commercial buildings, hotels, hospitals, industries,
                                   residences, institutes, etc. These systems are commissioned to give economy of operations and to achieve maximum
                                   utilisation of resources along with high quality indoor comfort, energy efficiency, safety and security, and most
                                   importantly better management of all systems under a singular unified platform.
                              </p>
                              <p>
                                   The services can be categorised into eight different verticals: <br />

                                   <router-link to="/" class="knowmore">Building Management Systems </router-link><br />
                                   <router-link to="/" class="knowmore">Public Addressing </router-link><br />
                                   <router-link to="/" class="knowmore">Fire- Safety </router-link><br />
                                   <router-link to="/" class="knowmore">Smart Home </router-link><br />
                                   <router-link to="/" class="knowmore">DAS </router-link><br />
                                   <router-link to="/" class="knowmore">Parking Management </router-link><br />
                                   <router-link to="/" class="knowmore">Scada </router-link><br />
                                   <router-link to="/" class="knowmore">Visitor Management </router-link><br />
                              </p>
                         </div>
                    </div>
                    <div class="column is-half"><img class="sideimg" src="@/assets/images/services/ibms.png" alt="" /></div>
               </div>

               <div class="columns details">
                    <div class="column is-half ">
                         <div class="line-top">
                              <div class="section-title">
                                   Building Management System (BMS)
                              </div>
                              <div>
                                   <p>
                                        CTL offers fully integrated interoperable Building Management Systems, which makes use of Network Control
                                        Units (NCU’s) & Direct Digital Controller (DDC) technology for controlling chilling machines and various pumps
                                        and fans in HVAC systems. The result is a centralised and comprehensive building management – operated,
                                        controlled and monitored by an operator knowing what’s happening in and around the entire building /
                                        Infrastructure and taking necessary corrective actions on time. CTL has gained worldwide experience in
                                        integrated MEP systems through the industry standard BACnet / Mod-bus. Our proposed solutions will be based on
                                        open architecture systems and seamless integration of fire/life safety, security, lighting and other HVAC
                                        controls.
                                   </p>
                              </div>
                         </div>
                    </div>
                    <div class="column is-half ">
                         <div class="line-top">
                              <div class="section-title">
                                   Integration Facilities
                              </div>
                              <p>
                                   Open protocol integration with other building systems. <br />
                                   Lighting control.<br />
                                   Energy conservation demand limiting / utility management.<br />
                                   Irrigation control.<br />
                                   Tenant billing.<br />
                                   Security / Access / CCTV integration.<br />
                                   Wide Area network/ IP support.<br />
                                   Fire / Life Safety Integration / Smoke control.<br />
                              </p>

                              <div class="para-large">
                                   View Projects that have availed CTLs Solution in this category.
                                   <br />
                                   <router-link :to="{name:'PROJECTS'}" class="knowmore">Know More</router-link>
                              </div>
                         </div>
                    </div>
               </div>

               <div class="columns details">
                    <div class="column is-half ">
                         <div class="line-top">
                              <div class="section-title">
                                   Fire Alarm & Fire Suppression Systems
                              </div>
                              <div>
                                   <p>
                                        CTL offers a wide range of Fire Safety Services: be it fire alarm, fire suppression or emergency lighting and
                                        helps you to maintain it. The engineering design division handles projects of varied sizes and purposes; be it
                                        residential, hotel, hospital, commercial building or oil & gas projects. Designs with flexible architecture,
                                        time-and-money-saving features, large screens, maintenance capabilities and unmatched message capacity deliver
                                        clear, accurate alarm message descriptions.
                                   </p>
                              </div>

                              <div class="para-large mt-60">
                                   View Projects that have availed CTLs Solution in this category.
                                   <br />
                                   <router-link to="{name:'PROJECTS'}" class="knowmore">Know More</router-link>
                              </div>
                         </div>
                    </div>
                    <div class="column is-half ">
                         <div class="line-top">
                              <div class="section-title">
                                   Public Address, Voice Evacuation And General Alarm Systems
                              </div>
                              <p>
                                   Public Address and General Alarm system utilises microprocessor technology and cutting-edge digital voice
                                   techniques to provide superior performance and reliability. Our expert team has years of experience in customising
                                   solutions for complex installations, where architectural aesthetics are very important.
                              </p>
                              <p>
                                   From remote system monitoring, to complete system health checks and individually addressable speakers, the
                                   innovative features are suitable for a wide range of applications, such as :
                              </p>
                              <p>
                                   Fully networkable system offers almost limitless audio distribution capability.
                              </p>
                              <p>
                                   Remote network interface reduces costs by allowing remote maintenance and system reconfiguration.
                              </p>
                              <p>
                                   Fault diagnosis and alarm reporting is shown on the access panel to provide real time information to the operator
                                   of the system for faster resolution of problems.
                              </p>
                              <p>
                                   Fully configurable messages and alarm tones offer practically unlimited capacity of expansion.
                              </p>
                         </div>
                    </div>
               </div>
          </div>
     </div>
</template>

<script>
     export default {};
</script>

<style lang="scss" scoped>
   

     
</style>
